<template>
  <div>
    <div v-if="noData == false">
      <apexchart
        width="100%"
        height="300"
        type="bar"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
    <NoDataFound v-else />
  </div>
</template>

<script>
import { englishToNepaliNumber } from "nepali-number";
import { defineAsyncComponent } from "vue";
const NoDataFound = defineAsyncComponent(() =>
  import("../components/NoDataFound.vue")
);
const VueApexCharts = defineAsyncComponent(() => import("vue3-apexcharts"));

export default {
  name: "BarChart",
  components: {
    apexchart: VueApexCharts,
    NoDataFound,
  },
  data() {
    return {};
  },
  props: {
    data: { type: Object, required: true },
    noData: { type: Boolean, required: false, default: true },
    datatype: { type: String, required: false, default: "budget" },
    gradient: {
      type: Object,
      required: false,
      default: () => {
        return {
          to: "#67C3F3",
          from: "#5A98F2",
        };
      },
    },
  },
  computed: {
    series() {
      let series = [];

      if (this.datatype == "budget") {
        series = [
          {
            name: "",
            data: Object.keys(this.data).map((k) => this.data[k].budget),
            projects: Object.keys(this.data).map((k) => this.data[k].projects),
          },
        ];
      } else {
        series = [
          {
            data: this.data.map((ele) => {
              return ele.total;
            }),
          },
        ];
      }

      return series;
    },
    options() {
      return {
        xaxis: {
          categories:
            this.datatype == "budget"
              ? Object.keys(this.data)
              : this.data.map((ele) => {
                  if (ele.category.length > 20) {
                    let arr = ele.category.split(" ");
                    let toLoop = Math.ceil(arr.length / 2);
                    let final = [];
                    for (let i = 0; i < toLoop; i++) {
                      let tmp = "";
                      tmp = tmp + arr[i * 2] + " " + (arr[i * 2 + 1] ?? "");
                      final.push(tmp);
                    }
                    return final;
                  } else {
                    return ele.category;
                  }
                }),
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            style: {
              colors: "#000",
              fontSize: "16px",
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              fontSize: "12px",
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            type: "diagonal2",
            shadeIntensity: 1,
            colorStops: [
              {
                offset: 0,
                color: this.gradient.from,
                opacity: 1,
              },
              {
                offset: 100,
                color: this.gradient.to,
                opacity: 1,
              },
            ],
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            columnWidth: "40%",
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: this.datatype == "budget" ? false : true,
          textAnchor: "end",
          offsetX: 0,
          style: {
            colors: ["#000"],
            fontSize: "15px",
          },
          formatter: function (val) {
            return englishToNepaliNumber(val);
          },
          dropShadow: {
            enabled: false,
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: this.datatype == "budget" ? true : false,
          y: {
            formatter: (value, index) => {
              if (this.datatype == "budget") {
                if (index && index.series) {
                  let { dataPointIndex } = index;

                  return ` <div><p><strong>रु. ${englishToNepaliNumber(
                    value / 1000
                  )}</strong> विभाजीत बजेट (रुपैयाँ हजारमा)</p>
                  <p><strong>${englishToNepaliNumber(
                    this.series[0].projects[dataPointIndex]
                  )}</strong> आयोजना र कार्यक्रम</p></div>`;
                }
              }
              return value;
            },
          },
          marker: {
            show: false,
          },
        },
        chart: {
          animations: {
            enabled: false,
          },
        },
      };
    },
  },
};
</script>
